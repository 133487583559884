import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from '@sonar-web/common/src/hooks';
import { downloadCSVFile } from '@sonar-web/common/src/utils/file';
import { fillDecimalPlaces, formatDateTimeSecondsPrecision } from '@sonar-web/common';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';
import FilterOperator from '@sonar-web/common/src/enums/FilterOperator';
import FilterType from '@sonar-web/common/src/enums/FilterType';
import { fetchWaterMeterExportReadsAsync, selectWaterMeterExportReads } from '../Watermeter/watermeterSlice';
import ReadType from '../Enums/ReadType';

const ExportMeasurementPointReads = ({ measurementPoint }) => {
	const { translate } = useLocale();
	const dispatch = useDispatch();

	const { pending } = useSelector(selectWaterMeterExportReads);

	const onClick = () => {
		const filters = [
			{
				value: measurementPoint.id,
				member: 'measurementPointId',
				filterOperator: FilterOperator.Equal.value,
				filterType: FilterType.Guid.value
			},
			{
				value: ReadType.Monitoring.value,
				member: 'type',
				filterOperator: FilterOperator.NotEqual.value,
				filterType: FilterType.Int.value
			}
		];

		dispatch(fetchWaterMeterExportReadsAsync(filters)).then((response) => {
			if (!response) return;

			const fileName = measurementPoint.displayName;
			const headers = `${translate('Common_WatermeterNumber')};${translate('Common_WatermeterType')};${translate(
				'DateAndTime'
			)};${translate('Common_Volume')};${translate('Common_ReverseVolume')}\n`;
			const rows = response.elements
				.map((d, index) => {
					const date = formatDateTimeSecondsPrecision(d.date) ?? '';
					const volume = fillDecimalPlaces(d.volume, 3) ?? '';
					const reverseVolume = fillDecimalPlaces(d.reverseVolume, 3) ?? '';
					const newLine = index < response.totalCount - 1 ? '\n' : '';
					return `${d.watermeterNumber};${d.watermeterType};${date};${volume};${reverseVolume}${newLine}`;
				})
				.join('');

			downloadCSVFile(headers + rows, fileName);
		});
	};

	return (
		<BaseButton
			name='exportReadsCSV'
			variant='outlined'
			onClick={onClick}
			pending={pending}
			progress
			sx={{ minWidth: 'fit-content' }}>
			ExportReads
		</BaseButton>
	);
};

export default ExportMeasurementPointReads;
