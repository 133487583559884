import { createSelector, createSlice } from '@reduxjs/toolkit';
import { fetchWatermeter, addWatermeter, editWatermeter } from './watermeterApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { getSliceDescriptor } from '@sonar-web/common';
import WaterMetersService from '../WaterMeterService';
import { slices } from '../Constants/Module';

const slice = 'watermeter';
const getWatermeter = (getState) => getState().watermeter.watermeter;

const initialWatermeterData = {
	id: null,
	number: '',
	watermeterTypeId: '',
	watermeterTypeName: ''
};

export const watermeterSlice = createSlice({
	name: slice,
	initialState: {
		addPending: false,
		addSuccess: false,
		fetchPending: false,
		fetchSuccess: false,
		watermeter: initialWatermeterData,
		metaData: null,
		fetchMetaDataPending: false,
		editMetaDataPending: false,
		exportReadsPending: false,
		exportReads: null,
		diameterAndLength: null,
		diameterAndLengthPending: false
	},
	reducers: {
		fetchWatermeterSucces: (state, action) => {
			state.watermeter = action.payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchWatermeterPending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		addWatermeterSucces: (state, action) => {
			state.addPending = false;
			state.addSuccess = action.payload;
		},
		addWatermeterPending: (state, action) => {
			state.addPending = action.payload ?? true;
		},
		setWatermeterData: (state, action) => {
			state.watermeter = action.payload;
		},
		resetWatermeterData: (state) => {
			state.watermeter = initialWatermeterData;
			state.addSuccess = false;
			state.fetchSuccess = false;
		},
		resetAddSuccess: (state) => {
			state.addSuccess = false;
		},
		fetchMetaDataPending: (state, { payload }) => {
			state.fetchMetaDataPending = payload;
		},
		fetchMetaDataSuccess: (state, { payload }) => {
			state.fetchMetaDataPending = false;
			state.metaData = payload;
		},
		setMetaData: (state, { payload }) => {
			state.metaData = payload || null;
		},
		editMetaDataPending: (state, { payload }) => {
			state.editMetaDataPending = payload;
		},
		fetchExportReadsSuccess: (state, { payload }) => {
			state.exportReadsPending = false;
			state.exportReads = payload?.elements;
		},
		fetchExportReadsPending: (state, { payload }) => {
			state.exportReadsPending = payload;
		},
		setDiameterAndLength: (state, { payload }) => {
			state.diameterAndLength = !payload.data
				? state.diameterAndLength
				: payload.data.map((d) => ({
						...d,
						valueKey: d['length'] ? `DN ${d.diameter} L ${d['length']}` : `DN ${d.diameter}`
				  }));
			state.diameterAndLengthPending = payload.pending;
		}
	}
});

export const {
	fetchWatermeterSucces,
	fetchWatermeterPending,
	addWatermeterSucces,
	addWatermeterPending,
	setWatermeterData,
	resetWatermeterData,
	resetAddSuccess,
	fetchMetaDataPending,
	fetchMetaDataSuccess,
	setMetaData,
	editMetaDataPending,
	fetchExportReadsPending,
	fetchExportReadsSuccess,
	setDiameterAndLength
} = watermeterSlice.actions;

export const fetchWatermeterAsync = (id) => async (dispatch) => {
	let response;

	try {
		dispatch(fetchWatermeterPending());
		response = await fetchWatermeter(id);
	} catch (error) {
		dispatch(fetchWatermeterPending(false));
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchWatermeterSucces(response));
};

export const addWatermeterAsync = () => async (dispatch, getState) => {
	const watermeter = { ...getWatermeter(getState) };
	let response;
	let result = Object.assign({}, watermeter);

	delete watermeter.watermeterTypeName;

	try {
		dispatch(addWatermeterPending());

		response = await addWatermeter(watermeter);
	} catch (error) {
		dispatch(addWatermeterPending(false));

		const errorResult = await dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));

		return errorResult;
	}

	result.id = response.id;

	dispatch(addWatermeterSucces(response.id ? result : true));
};

export const editWatermeterAsync = () => async (dispatch, getState) => {
	const watermeter = getWatermeter(getState);

	try {
		dispatch(addWatermeterPending());
		await editWatermeter(watermeter);
		dispatch(addWatermeterSucces(true));
		setTimeout(() => dispatch(resetAddSuccess()), 200);
	} catch (error) {
		dispatch(addWatermeterPending(false));

		const errorResult = await dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));

		return errorResult;
	}
};

export const fetchWaterMeterMetaDataAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchMetaDataPending(true));
		const response = await WaterMetersService.fetchWaterMeterMetaData(id);
		dispatch(fetchMetaDataSuccess(response));
	} catch (error) {
		dispatch(fetchMetaDataPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const editWaterMeterMetaDataAsync = (id) => async (dispatch, getState) => {
	try {
		dispatch(editMetaDataPending(true));
		const metaData = getState().watermeter.metaData;
		await WaterMetersService.editWaterMeterMetaData(id, metaData);
		dispatch(editMetaDataPending(false));
	} catch (error) {
		dispatch(editMetaDataPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchWaterMeterExportReadsAsync = (filters) => async (dispatch, getState) => {
	try {
		const pd = getSliceDescriptor(getState, slices.watermeterReads, null, null, Math.pow(2, 31) - 1);
		pd.FilterDescriptors = [...pd.FilterDescriptors, ...filters];
		pd.Offset = 0;

		dispatch(fetchExportReadsPending(true));
		const response = await WaterMetersService.fetchWatermeterReads(pd);
		dispatch(fetchExportReadsSuccess(response));
		return response;
	} catch (error) {
		dispatch(fetchExportReadsPending(false));
		dispatch(addErrorAsync({ slice: slices.watermeterReads, error }));
	}
};

export const fetchDiameterAndLengthAsync = (id) => async (dispatch) => {
	try {
		dispatch(setDiameterAndLength({ pending: true }));
		const response = await WaterMetersService.fetchDiameterAndLength(id);
		dispatch(setDiameterAndLength({ pending: false, data: response }));
	} catch (error) {
		dispatch(setDiameterAndLength({ pending: false }));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectWatermeter = (state) => {
	return {
		dataRow: state.watermeter.watermeter,
		fetchPending: state.watermeter.fetchPending,
		fetchSuccess: state.watermeter.fetchSuccess
	};
};

export const selectWatermeterAdd = (state) => {
	return {
		addPending: state.watermeter.addPending,
		addSuccess: state.watermeter.addSuccess
	};
};

export const selectWaterMeterMetaData = createSelector(
	(state) => state.watermeter,
	(waterMeter) => ({
		pending: waterMeter.fetchMetaDataPending,
		metaData: waterMeter.metaData
	})
);

export const selectEditMetaData = createSelector(
	(state) => state.watermeter,
	(waterMeter) => ({
		pending: waterMeter.editMetaDataPending
	})
);

export const selectWaterMeterExportReads = createSelector(
	(state) => state.watermeter,
	(waterMeter) => ({
		pending: waterMeter.exportReadsPending,
		exportReads: waterMeter.exportReads
	})
);

export const selectDiameterAndLength = createSelector(
	(state) => state.watermeter,
	(waterMeter) => ({
		pending: waterMeter.diameterAndLengthPending,
		diameterAndLength: waterMeter.diameterAndLength
	})
);

export default watermeterSlice.reducer;
