import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.REPORTS;

async function fetchCurrentNumberOfReports() {
	const { data } = await apiBase(`currentNumberOfReports`, 'get', {
		path
	});

	return data;
}

export async function fetchReportTypes(pageDescriptor) {
	const { data } = await apiBase(`reporttypes`, 'get', {
		path,
		params: { pageDescriptor }
	});

	const elements = data?.elements?.map((r) => {
		let columns = [];
		let data = {};

		try {
			data = JSON.parse(r.data);
			if (data) columns = data.columns;
		} catch (e) {
			columns = [];
		}

		return {
			...r,
			name: `Reports_${r.reportType}`,
			columns: columns
		};
	});

	return { elements, totalCount: data?.totalCount ?? 0 };
}

const ReportsService = {
	fetchCurrentNumberOfReports,
	fetchReportTypes
};

export default ReportsService;
