import moment from 'moment';
import { FormControl, Typography } from '@mui/material';
import { useLocale } from '../../hooks';
import { BaseMonthYearPicker } from '../../components';

const ProductionDate = ({ formik, disableAutoLegalisationSet = false, showError = true, onChange, ...rest }) => {
	const { translate } = useLocale();
	const { values, errors, setFieldValue, setFieldError } = formik;

	const onProductionDateChange = (val) => {
		if (!val) {
			setFieldValue('productionDate', val, true);
			return;
		}
		if (!val.isValid()) {
			setFieldError('productionDate', 'Common_InvalidDataFormat');
			return;
		}
		setFieldValue('productionDate', val.toISOString(), true);
		if (!disableAutoLegalisationSet && !values.legalisationDate)
			setFieldValue('legalisationDate', moment(val).set('month', 10).toISOString(), true);

		onChange?.(val);
	};

	return (
		<FormControl fullWidth margin='normal'>
			<BaseMonthYearPicker
				name='productionDate'
				value={values.productionDate}
				label='ProductionDate'
				onChange={onProductionDateChange}
				error={Boolean(errors.productionDate)}
				fullWidth
				openTo='year'
				views={['year']}
				textFieldProps={{
					variant: 'outlined',
					size: 'small'
				}}
				{...rest}
			/>
			{showError && errors.productionDate && (
				<Typography variant='caption' color='error'>
					{translate(errors.productionDate)}
				</Typography>
			)}
		</FormControl>
	);
};

export default ProductionDate;
