import { FormControl, Select, Typography } from '@mui/material';
import BaseSelectMenuItem from '@sonar-web/common/src/components/BaseSelectMenuItem/BaseSelectMenuItem';
import { useLocale } from '@sonar-web/common/src/hooks';
import { getAggregatedSetWaterMeters } from '../Utils/aggregatedHelpers';
import EventBus from '@sonar-web/common/src/helpers/EventBus';
import Messages from '../Constants/Messages';

const WaterMeterSelect = ({ location, current }) => {
	const { translate } = useLocale();
	const { main, additional } = getAggregatedSetWaterMeters(location);
	const value = current.id === main.id ? 'main' : 'additional';

	const onChange = (e) => {
		const device = e.target.value === 'main' ? main : additional;
		EventBus.dispatch(Messages.AggreagtedWaterMeterSelected, { device });
	};

	return (
		<FormControl margin='none' variant='outlined' size='small' fullWidth>
			<Select
				name='WaterMeterSelect'
				value={value}
				onChange={onChange}
				variant='outlined'
				size='small'
				margin='none'>
				<BaseSelectMenuItem value='main'>
					{translate('WatermeterNumber')} 1:{' '}
					<Typography component='span' fontWeight='medium' fontSize='inherit'>
						{main.number}{' '}
					</Typography>
					({main.typeName})
				</BaseSelectMenuItem>
				<BaseSelectMenuItem value='additional'>
					{translate('WatermeterNumber')} 2:{' '}
					<Typography component='span' fontWeight='medium' fontSize='inherit'>
						{additional.number}{' '}
					</Typography>
					({additional.typeName})
				</BaseSelectMenuItem>
			</Select>
		</FormControl>
	);
};

export default WaterMeterSelect;
