/* eslint-disable react/display-name */
import { forwardRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import {
	BaseLinearProgress,
	BaseCircularProgress,
	ListActionToolbar,
	listScrollToElement,
	PanelList
} from '@sonar-web/common';
import { useFilterDescriptor } from '@sonar-web/common/src/hooks';
import useList from '@sonar-web/common/src/features/List/useList';
import { fetchThunk, setOffset } from '@sonar-web/common/src/features/List/genericListSlice';
import useListSorting from '@sonar-web/common/src/components/Sorters/useListSorting';
import ReadType from '@sonar-web/common/src/enums/ReadType';
import EmptyPlaceholder from '@sonar-web/common/src/components/EmptyPlaceholder/EmptyPlaceholder';
import ReadsIconLight from '@sonar-web/common/src/icons/ReadsIconLight';
import { watermeterReadsSorters } from './watermeterReadsSorters';
import { slices } from '../Constants/Module';
import { fetchWatermeterReads } from './watermeterReadsApi';
import WatermeterReadsLocationHeader from './WatermeterReadsLocationHeader';
import WatermeterReadsLocationListItem from './WatermeterReadsLocationListItem';
import WatermeterReadsLocationFilters from './WatermeterReadsLocationFilters';
import ExportMeasurementPointReads from '../Components/ExportMeasurementPointReads';
import useListFiltering from '@sonar-web/common/src/components/Filters/useListFiltering';
import ReadAdd from '../Read/ReadAdd';
import FilterOperator from '@sonar-web/common/src/enums/FilterOperator';
import FilterType from '@sonar-web/common/src/enums/FilterType';
import WaterMeterSelect from './WaterMeterSelect';

const slice = slices.watermeterReads;

const AggregatedWatermeterReadsListLocations = ({ location, currentWaterMeter }) => {
	const dispatch = useDispatch();
	const { listSelector } = useList(slice);
	const { createFilter } = useFilterDescriptor();

	const [isReloading, setIsReloading] = useState(true);

	const { elements, hasMore, pending, totalCount } = listSelector;
	const elementsCount = elements?.length;
	const hasRecords = elementsCount !== 0;

	const fetchData = (append = false) => {
		if (append) {
			if (!hasMore) return;
		} else {
			listScrollToElement(0, 'WatermeterReadsList');
		}

		const defaultFilters = createFilter([
			{
				member: { name: 'watermeterId', type: FilterType.Guid.name },
				value: currentWaterMeter.id,
				operator: FilterOperator.Equal.value
			},
			{
				member: { name: 'type', type: FilterType.Int.name },
				value: ReadType.Monitoring.value,
				operator: FilterOperator.NotEqual.value
			}
		]);

		dispatch(setOffset(slice)(append));
		setIsReloading(!append);
		dispatch(fetchThunk(slice)({ endpoint: fetchWatermeterReads, payload: { defaultFilters } }));
	};

	useListSorting({ slice, callback: fetchData });
	useListFiltering({ slice, callback: fetchData });

	useEffect(() => {
		fetchData();
	}, []);

	if (!elements) return null;
	return (
		<>
			<ListActionToolbar
				slice={slice}
				pending={pending}
				sorters={watermeterReadsSorters}
				filters={WatermeterReadsLocationFilters}
				totalCount={totalCount}>
				<WaterMeterSelect location={location} current={currentWaterMeter} />
				<ReadAdd location={location} />
				<ExportMeasurementPointReads measurementPoint={location} />
			</ListActionToolbar>
			<BaseLinearProgress pending={pending && isReloading} />
			{hasRecords && <WatermeterReadsLocationHeader />}
			<Virtuoso
				style={{
					minHeight: window.innerHeight <= 600 ? '100vh' : '500px'
				}}
				overscan={20}
				data={elements}
				components={{ List, EmptyPlaceholder: Placeholder }}
				itemContent={(index) => <WatermeterReadsLocationListItem row={elements[index]} />}
				totalCount={elementsCount}
				endReached={() => fetchData(true)}
			/>
			<BaseCircularProgress pending={pending && !isReloading} paddingTop={3} />
		</>
	);
};

export default AggregatedWatermeterReadsListLocations;

const List = forwardRef(({ style, children }, listRef) => {
	return (
		<PanelList id='WatermeterReadsList' ref={listRef} style={style} disablePadding>
			{children}
		</PanelList>
	);
});

const Placeholder = () => (
	<EmptyPlaceholder
		text='Watermeters_WatermeterReadsListEmpty'
		data-at='WatermeterReadsListEmpty'
		icon={ReadsIconLight}
	/>
);
