import MeasuringKind from '@sonar-web/common/src/enums/MeasuringKind';

export const isAggregatedSetInLocation = (location) => {
	if (!location.currentSet) return false;
	return location.currentSet?.measurementPairs.some((pair) => pair.measuringKind === MeasuringKind.Aggregated.name);
};

export const getAggregatedSetWaterMeters = (location) => {
	const main = location.currentSet?.measurementPairs.find(
		(pair) => pair.measuringKind === MeasuringKind.Main.name
	)?.inputDevice;
	const additional = location.currentSet?.measurementPairs.find(
		(pair) => pair.measuringKind === MeasuringKind.Additional.name
	)?.inputDevice;

	return { main, additional };
};
