import { FormControl, InputLabel, Select } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import FilterOperator from '@sonar-web/common/src/enums/FilterOperator';
import BaseSelectMenuItem from '@sonar-web/common/src/components/BaseSelectMenuItem/BaseSelectMenuItem';

const OperatorSelect = ({ name, value, onChange }) => {
	const { translate } = useLocale();

	return (
		<FormControl margin='normal' variant='outlined' size='small'>
			<InputLabel id='PointsGroupType'>{translate(name)}</InputLabel>
			<Select
				value={value}
				onChange={onChange}
				variant='outlined'
				size='small'
				labelId='PointsGroupType'
				label={translate(name)}>
				<BaseSelectMenuItem value={99}>{translate(`ReportProductionAndLegalisation.Any`)}</BaseSelectMenuItem>
				<BaseSelectMenuItem value={FilterOperator.Equal.value}>
					{translate(`ReportProductionAndLegalisation.${FilterOperator.Equal.name}`)}
				</BaseSelectMenuItem>
				<BaseSelectMenuItem value={FilterOperator.Lt.value}>
					{translate(`ReportProductionAndLegalisation.${FilterOperator.Lt.name}`)}
				</BaseSelectMenuItem>
			</Select>
		</FormControl>
	);
};

export default OperatorSelect;
