import { FormControl, Typography } from '@mui/material';
import { useLocale } from '../../hooks';
import { BaseMonthYearPicker } from '../../components';

const LegalisationDate = ({ formik, showError = true, onChange, ...rest }) => {
	const { translate } = useLocale();
	const { values, errors, setFieldValue, setFieldError } = formik;

	const onLegalisationDateChange = (val) => {
		if (!val) {
			setFieldValue('legalisationDate', val, true);
			return;
		}
		if (!val.isValid()) {
			setFieldError('legalisationDate', 'Common_InvalidDataFormat');
			return;
		}
		setFieldValue('legalisationDate', val.toISOString(), true);

		onChange?.(val);
	};

	return (
		<FormControl fullWidth margin='normal'>
			<BaseMonthYearPicker
				name='legalisationDate'
				value={values.legalisationDate}
				label='LegalisationDate'
				onChange={onLegalisationDateChange}
				error={Boolean(errors.legalisationDate)}
				fullWidth
				textFieldProps={{
					variant: 'outlined',
					size: 'small'
				}}
				{...rest}
			/>
			{showError && errors.legalisationDate && (
				<Typography variant='caption' color='error'>
					{translate(errors.legalisationDate)}
				</Typography>
			)}
		</FormControl>
	);
};

export default LegalisationDate;
