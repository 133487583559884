import { useContext } from 'react';
import EventBus from '@sonar-web/common/src/helpers/EventBus';
import DashboardContentContext from '@sonar-web/common/src/components/Dashboard/dashboardContentContext';
import ButtonAdd from '@sonar-web/common/src/components/Buttons/ButtonAdd';
import Messages from '../Constants/Messages';
import ReadFormAggregated from './ReadFormAggregated';
import ReadForm from './ReadForm';
import { getAggregatedSetWaterMeters, isAggregatedSetInLocation } from '../Utils/aggregatedHelpers';

const ReadAdd = ({ location }) => {
	const { setCoverComponent } = useContext(DashboardContentContext);

	if (!location.currentSet) return null;

	const onClick = () => {
		if (isAggregatedSetInLocation(location)) {
			const { main, additional } = getAggregatedSetWaterMeters(location);

			setCoverComponent(
				<ReadFormAggregated
					main={main}
					additional={additional}
					startDate={location.currentSet.startDate}
					onClose={() => setCoverComponent(null)}
					onSuccess={() => {
						setCoverComponent(null);
						EventBus.dispatch(Messages.ManualReadAdded);
					}}
				/>
			);
			return;
		}

		setCoverComponent(
			<ReadForm
				watermeterId={location.currentSet.measurementPairs[0].inputDevice.id}
				startDate={location.currentSet.startDate}
				onClose={() => setCoverComponent(null)}
				onSuccess={() => {
					setCoverComponent(null);
					EventBus.dispatch(Messages.ManualReadAdded);
				}}
			/>
		);
	};

	return (
		<ButtonAdd name='read_add' onClick={onClick} sx={{ minWidth: 'fit-content' }}>
			Read
		</ButtonAdd>
	);
};

export default ReadAdd;
