import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const pathHub = REST_API_PATHS.MESHHUB;
const pathNetwork = REST_API_PATHS.MESHNETWORK;
const pathTerminal = REST_API_PATHS.MESHTERMINAL;

async function fetchHubs(pageDescriptor) {
	const { data } = await apiBase(`hubs`, 'get', {
		path: pathHub,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchHub(id) {
	const { data } = await apiBase(`hubs/${id}`, 'get', {
		path: pathHub
	});

	return data;
}

async function addHub(hub) {
	const response = await apiBase(`hubs`, 'post', {
		path: pathHub,
		data: hub
	});

	const locationHeader = response.headers.location;

	if (locationHeader) return locationHeader.slice(locationHeader.indexOf('/') + 1);
	return response;
}

async function editHub(hub) {
	return await apiBase(`hubs/${hub.id}`, 'put', {
		path: pathHub,
		data: hub
	});
}

async function deleteHub(id) {
	return await apiBase(`hubs/${id}`, 'delete', {
		path: pathHub
	});
}

async function fetchHubTypes(pageDescriptor) {
	const { data } = await apiBase(`hubsTypes`, 'get', {
		path: pathHub,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchHubType(hubTypeId) {
	const { data } = await apiBase(`hubsTypes/${hubTypeId}`, 'get', {
		path: pathHub
	});

	return data;
}

async function fetchHubConfigurationState(id) {
	const { data } = await apiBase(`hubs/${id}/state`, 'get', {
		path: pathHub
	});

	return data;
}

async function fetchHubInfo(id) {
	const { data } = await apiBase(`hubs/${id}/info`, 'get', {
		path: pathHub
	});

	return data;
}

async function deleteHubPendingConfiguration(id) {
	return await apiBase(`hubs/${id}/configuration`, 'delete', {
		path: pathHub
	});
}

async function fetchHubDiagnostic(pageDescriptor) {
	const { data } = await apiBase(`hub/diagnosticReads`, 'get', {
		path: pathHub,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchNetworks(pageDescriptor) {
	const { data } = await apiBase(`meshNetworks`, 'get', {
		path: pathNetwork,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchNetwork(id) {
	const { data } = await apiBase(`meshNetworks/${id}`, 'get', {
		path: pathNetwork
	});

	return data;
}

async function addNetwork(network) {
	const response = await apiBase(`meshNetworks`, 'post', {
		path: pathNetwork,
		data: network
	});

	const locationHeader = response.headers.location;

	if (locationHeader) return locationHeader.slice(locationHeader.indexOf('/') + 1);
	return null;
}

async function editNetwork(network) {
	return await apiBase(`meshNetworks/${network.id}`, 'put', {
		path: pathNetwork,
		data: network
	});
}

async function deleteNetwork(id) {
	return await apiBase(`meshNetworks/${id}`, 'delete', {
		path: pathNetwork
	});
}

async function fetchTerminal(id) {
	const { data } = await apiBase(`devices/${id}`, 'get', {
		path: pathTerminal
	});

	return data;
}

async function addTerminal(device) {
	const response = await apiBase(`devices`, 'post', {
		path: pathTerminal,
		data: device
	});

	const deviceHeader = response.headers.location;

	if (deviceHeader)
		return {
			url: deviceHeader,
			id: deviceHeader.slice(deviceHeader.indexOf('/') + 1)
		};

	return response;
}

async function editTerminal(terminal) {
	return await apiBase(`devices/${terminal.id}`, 'put', {
		path: pathTerminal,
		data: terminal
	});
}

async function deleteTerminal(id) {
	return await apiBase(`devices/${id}`, 'delete', {
		path: pathTerminal
	});
}

async function fetchTerminalType(terminalTypeId) {
	const { data } = await apiBase(`deviceTypes/${terminalTypeId}`, 'get', {
		path: pathTerminal
	});

	return data;
}

async function assignHubToNetwork(assignData) {
	return await apiBase(`hubs/assignToMeshNetwork`, 'put', {
		path: pathHub,
		data: assignData
	});
}

async function replaceHubInNetwork(assignData) {
	return await apiBase(`hubs/replaceInMeshNetwork`, 'put', {
		path: pathHub,
		data: assignData
	});
}

async function fetchNetworkStructureItems(pageDescriptor) {
	const { data } = await apiBase(`meshNetworks/devices`, 'get', {
		path: pathNetwork,
		params: { pageDescriptor }
	});

	return data;
}

async function assignItemsToNetwork(meshNetworkId, assignData) {
	return await apiBase(`meshNetworks/${meshNetworkId}/assignDevices`, 'put', {
		path: pathNetwork,
		data: { ...assignData, meshNetworkId }
	});
}

async function unassignItemsFromNetwork(meshNetworkId, assignData) {
	return await apiBase(`meshNetworks/${meshNetworkId}/unAssignDevices`, 'put', {
		path: pathNetwork,
		data: { ...assignData, meshNetworkId }
	});
}

async function updateNetworkCycleLength(networkId, cycle) {
	return await apiBase(`meshNetworks/${networkId}/updateCycleLength`, 'put', {
		path: pathNetwork,
		data: cycle
	});
}

async function fetchNetworkProperties() {
	const { data } = await apiBase(`meshNetworks/properties`, 'get', {
		path: pathNetwork
	});

	return data;
}

async function addNetworkSchedule(scheduleInfo) {
	return await apiBase(`meshNetworks/scheduleInfo`, 'post', {
		path: pathNetwork,
		data: scheduleInfo
	});
}

async function editNetworkSchedule(scheduleInfo) {
	return await apiBase(`meshNetworks/scheduleInfo`, 'put', {
		path: pathNetwork,
		data: scheduleInfo
	});
}

async function fetchNetworkSchedule(networkId) {
	const { data } = await apiBase(`meshNetworks/${networkId}/scheduleInfo`, 'get', {
		path: pathNetwork
	});

	return data;
}

async function deleteNetworkSchedule(networkId) {
	return await apiBase(`meshNetworks/${networkId}/scheduleInfo`, 'delete', {
		path: pathNetwork
	});
}

async function fetchRoutingTables(pageDescriptor) {
	const { data } = await apiBase(`routingTables`, 'get', {
		path: pathNetwork,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchRoutingTableCsv({ id }) {
	const { data } = await apiBase(`routingtables/${id}/csvFileData`, 'get', {
		path: pathNetwork,
		responseType: 'blob'
	});

	return data;
}

async function fetchRoutingTableVisualization(id) {
	const { data } = await apiBase(`routingtables/${id}/treeData`, 'get', {
		path: pathNetwork
	});

	if (!data) return [];
	const treeData = !Array.isArray(data) ? [data] : [...data];

	function updateChildrenCount(obj) {
		if (!Array.isArray(obj.children)) {
			obj.childrenCount = 0;
			return obj.childrenCount;
		}

		obj.childrenCount = obj.children.length;

		for (const child of obj.children) {
			obj.childrenCount += updateChildrenCount(child);
		}

		return obj.childrenCount;
	}

	for (const td of treeData) {
		updateChildrenCount(td);
	}

	const withFakeRoot = {
		id: '00000000-0000-0000-0000-000000000000',
		children: treeData
	};

	return withFakeRoot;
}

async function fetchTerminals(pageDescriptor) {
	const { data } = await apiBase(`devices`, 'get', {
		path: pathTerminal,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchTransmits(pageDescriptor, { networkId }) {
	const { data } = await apiBase(`meshNetworks/${networkId}/transmits`, 'get', {
		path: pathNetwork,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchTransmit({ networkId, id }) {
	const { data } = await apiBase(`meshNetworks/${networkId}/transmits/${id}`, 'get', {
		path: pathNetwork
	});

	return data;
}

async function addTransmit({ networkId, transmit }) {
	return await apiBase(`meshNetworks/${networkId}/transmits`, 'post', {
		path: pathNetwork,
		data: transmit
	});
}

async function editTransmit({ networkId, transmit }) {
	return await apiBase(`meshNetworks/${networkId}/transmits/${transmit.id}`, 'put', {
		path: pathNetwork,
		data: transmit
	});
}

async function deleteTransmit({ networkId, id }) {
	return await apiBase(`meshNetworks/${networkId}/transmits/${id}`, 'delete', {
		path: pathNetwork
	});
}

async function fetchTransmitsStats(networkId) {
	const { data } = await apiBase(`meshNetworks/${networkId}/transmitsStats`, 'get', {
		path: pathNetwork
	});

	return data;
}

async function fetchNetworkAddresses() {
	const { data } = await apiBase(`meshNetworks/addresses`, 'get', {
		path: pathNetwork
	});

	return data;
}

async function fetchNetworkHubStructureItems(pageDescriptor) {
	const { data } = await apiBase(`meshNetworks/hubDevices`, 'get', {
		path: pathNetwork,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchTerminalsTypes(pageDescriptor) {
	const { data } = await apiBase(`deviceTypes`, 'get', {
		path: pathTerminal,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchNetworkHubStructureUnknownItems(pageDescriptor) {
	const { data } = await apiBase(`meshNetworks/hubDevices/Unknown`, 'get', {
		path: pathNetwork,
		params: { pageDescriptor }
	});

	return data;
}

async function routingTableDataReadSet({ networkId, value }) {
	const { data } = await apiBase(`meshNetworks/${networkId}/routingTableRead`, 'post', {
		path: pathNetwork,
		data: { value }
	});

	return data;
}

async function routingTableMonitoringSet({ networkId, value }) {
	const { data } = await apiBase(`meshNetworks/${networkId}/routingTableMonitoring`, 'post', {
		path: pathNetwork,
		data: { value }
	});

	return data;
}

async function routingTableDeleteSet({ networkId, value }) {
	const { data } = await apiBase(`meshNetworks/${networkId}/routingTableDelete`, 'post', {
		path: pathNetwork,
		data: { value }
	});

	return data;
}

async function fetchRoutingTablesFlags(networkId) {
	const { data } = await apiBase(`meshNetworks/${networkId}/routingTableSettings`, 'get', {
		path: pathNetwork
	});

	return data;
}

async function fetchNetworkStructureFlags(networkId) {
	const { data } = await apiBase(`meshNetworks/${networkId}/structureSettings`, 'get', {
		path: pathNetwork
	});

	return data;
}

async function networkStructureReadSet({ networkId, value }) {
	const { data } = await apiBase(`meshNetworks/${networkId}/networkStructureRead`, 'post', {
		path: pathNetwork,
		data: { value }
	});

	return data;
}

async function unrecognizedDevicesReadSet({ networkId, value }) {
	const { data } = await apiBase(`meshNetworks/${networkId}/unrecognizedDevicesRead`, 'post', {
		path: pathNetwork,
		data: { value }
	});

	return data;
}

async function unrecognizedDevicesMonitoringSet({ networkId, value }) {
	const { data } = await apiBase(`meshNetworks/${networkId}/unrecognizedDevicesMonitoring`, 'post', {
		path: pathNetwork,
		data: { value }
	});

	return data;
}

async function unrecognizedDevicesDeleteSet({ networkId, value }) {
	const { data } = await apiBase(`meshNetworks/${networkId}/unrecognizedDevicesDelete`, 'post', {
		path: pathNetwork,
		data: { value }
	});

	return data;
}

async function fetchHubTemperatureProfiles(pageDescriptor) {
	const { data } = await apiBase(`hub/temperatureProfiles`, 'get', {
		path: pathHub,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchTerminalTransmits(pageDescriptor) {
	const { data } = await apiBase(`transmits`, 'get', {
		path: pathTerminal,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchCerisReaderStructureFile({ id }) {
	const { data } = await apiBase(`meshNetworks/${id}/haConfigurationCsv`, 'get', {
		path: pathNetwork,
		responseType: 'blob'
	});

	return data;
}

async function fetchHubFirmware({ deviceTypeName }) {
	const { data } = await apiBase(`firmware/${deviceTypeName}`, 'get', {
		path: pathHub
	});

	return data;
}

async function setHubFirmwareUpdate(data) {
	return await apiBase(`firmware/update`, 'put', {
		path: pathHub,
		data: data
	});
}

const MeshService = {
	fetchHubs,
	fetchHub,
	addHub,
	editHub,
	deleteHub,
	fetchHubTypes,
	fetchHubType,
	fetchHubConfigurationState,
	fetchHubInfo,
	deleteHubPendingConfiguration,
	fetchHubDiagnostic,
	fetchNetworks,
	fetchNetwork,
	addNetwork,
	editNetwork,
	deleteNetwork,
	fetchTerminal,
	addTerminal,
	editTerminal,
	deleteTerminal,
	fetchTerminalType,
	assignHubToNetwork,
	replaceHubInNetwork,
	fetchNetworkStructureItems,
	fetchNetworkHubStructureUnknownItems,
	assignItemsToNetwork,
	unassignItemsFromNetwork,
	updateNetworkCycleLength,
	fetchNetworkProperties,
	addNetworkSchedule,
	editNetworkSchedule,
	fetchNetworkSchedule,
	deleteNetworkSchedule,
	fetchRoutingTables,
	fetchRoutingTableCsv,
	fetchTerminals,
	fetchTransmits,
	fetchTransmit,
	addTransmit,
	editTransmit,
	deleteTransmit,
	fetchTransmitsStats,
	fetchNetworkHubStructureItems,
	fetchNetworkAddresses,
	fetchRoutingTableVisualization,
	fetchTerminalsTypes,
	routingTableDataReadSet,
	routingTableMonitoringSet,
	routingTableDeleteSet,
	fetchRoutingTablesFlags,
	fetchNetworkStructureFlags,
	networkStructureReadSet,
	unrecognizedDevicesReadSet,
	unrecognizedDevicesMonitoringSet,
	unrecognizedDevicesDeleteSet,
	fetchHubTemperatureProfiles,
	fetchTerminalTransmits,
	fetchCerisReaderStructureFile,
	fetchHubFirmware,
	setHubFirmwareUpdate
};

export default MeshService;
