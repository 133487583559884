import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Stack } from '@mui/material';
import ProductionDate from '@sonar-web/common/src/features/ProductionDate/ProductionDate';
import LegalisationDate from '@sonar-web/common/src/features/LegalisationDate/LegalisationDate';
import OperatorSelect from './OperatorSelect';

const ReportProductionAndLegalisation = ({ stepIndex, useConfigurator, configuratorData }) => {
	const { updateConfiguratorData, updateStepValid, isValid, visitedSteps } = useConfigurator();

	const visited = visitedSteps[stepIndex];
	const columns = configuratorData.reportSettings?.columns;
	const productionColumnIndex = columns.findIndex((c) => c.columnName === 'Devices_ProductionDate');
	const productionColumnFilter = columns[productionColumnIndex].filterValues?.[0];
	const legalisationColumnIndex = columns.findIndex((c) => c.columnName === 'Devices_LegalisationDate');
	const legalisationColumnFilter = columns[legalisationColumnIndex].filterValues?.[0];

	const formik = useFormik({
		initialValues: {
			productionDate: productionColumnFilter?.value || null,
			legalisationDate: legalisationColumnFilter?.value || null,
			productionDateOperator: productionColumnFilter?.operator || 99,
			legalisationDateOperator: legalisationColumnFilter?.operator || 99
		},
		validationSchema,
		onSubmit: () => {}
	});

	const onProductionOperatorChange = (e) => {
		const newValue = e.target.value;
		const newDate = newValue === 99 ? null : formik.values.productionDate;

		formik.setFieldValue('productionDateOperator', newValue);
		if (newValue === 99)
			setTimeout(() => {
				formik.setFieldValue('productionDate', null);
			}, 200);

		updateColumns(columns, productionColumnIndex, newDate, newValue, updateConfiguratorData);
	};

	const onLegalisationOperatorChange = (e) => {
		const newValue = e.target.value;
		const newDate = newValue === 99 ? null : formik.values.legalisationDate;

		formik.setFieldValue('legalisationDateOperator', newValue);
		if (newValue === 99)
			setTimeout(() => {
				formik.setFieldValue('legalisationDate', null);
			}, 200);

		updateColumns(columns, legalisationColumnIndex, newDate, newValue, updateConfiguratorData);
	};

	const onProductionDateChange = (val) => {
		updateColumns(
			columns,
			productionColumnIndex,
			val.toISOString(),
			formik.values.productionDateOperator,
			updateConfiguratorData
		);
	};

	const onLegalisationDateChange = (val) => {
		updateColumns(
			columns,
			legalisationColumnIndex,
			val.toISOString(),
			formik.values.legalisationDateOperator,
			updateConfiguratorData
		);
	};

	useEffect(() => {
		if (!visited) return;
		if (isValid[stepIndex] && formik.isValid) return;
		updateStepValid(stepIndex, formik.isValid);
	}, [visited, formik.isValid, formik.errors]);

	if (columns?.length === 0) return null;
	return (
		<form onSubmit={formik.handleSubmit}>
			<Stack
				direction='row'
				alignItems='flex-start'
				gap='.5rem'
				sx={{
					'& .MuiPaper-root': {
						mt: '15px'
					},
					'& > div:nth-child(1)': {
						flex: '1 1 auto'
					},
					'& > div:nth-child(2)': {
						width: 250
					}
				}}>
				<OperatorSelect
					name='ProductionDate'
					value={formik.values.productionDateOperator}
					onChange={onProductionOperatorChange}
				/>
				{formik.values.productionDateOperator !== 99 && (
					<ProductionDate
						onChange={onProductionDateChange}
						formik={formik}
						label=''
						disableAutoLegalisationSet={true}
						error={Boolean(formik.touched.productionDate && formik.errors.productionDate)}
						showError={Boolean(formik.touched.productionDate && formik.errors.productionDate)}
						textFieldProps={{
							variant: 'outlined',
							size: 'small'
						}}
					/>
				)}
			</Stack>
			<Stack
				direction='row'
				alignItems='flex-start'
				gap='.5rem'
				sx={{
					'& .MuiPaper-root': {
						mt: '15px'
					},
					'& > div:nth-child(1)': {
						flex: '1 1 auto'
					},
					'& > div:nth-child(2)': {
						width: 250
					}
				}}>
				<OperatorSelect
					name='LegalisationDate'
					value={formik.values.legalisationDateOperator}
					onChange={onLegalisationOperatorChange}
				/>
				{formik.values.legalisationDateOperator !== 99 && (
					<LegalisationDate
						onChange={onLegalisationDateChange}
						formik={formik}
						label=''
						error={Boolean(formik.touched.legalisationDate && formik.errors.legalisationDate)}
						showError={Boolean(formik.touched.legalisationDate && formik.errors.legalisationDate)}
						textFieldProps={{
							variant: 'outlined',
							size: 'small'
						}}
					/>
				)}
			</Stack>
		</form>
	);
};

export default ReportProductionAndLegalisation;

const validationSchema = Yup.object().shape({
	productionDate: Yup.string().nullable(),
	legalisationDate: Yup.string().nullable(),
	productionDateOperator: Yup.number().test('isAny', 'AtLeastOneValueIsRequired', (value, ctx) => {
		if (value === 99) return true;
		return ctx.parent.productionDate !== null;
	}),
	legalisationDateOperator: Yup.number().test('isAny', 'AtLeastOneValueIsRequired', (value, ctx) => {
		if (value === 99) return true;
		return ctx.parent.legalisationDate !== null;
	})
});

const updateColumns = (columns, index, value, operator, updateConfiguratorData) => {
	const newColumns = [...columns];
	const newColumn = { ...columns[index] };
	const newFilterValues = value
		? [
				{
					value,
					operator
				}
		  ]
		: null;

	newColumn.filterValues = newFilterValues;
	newColumns[index] = newColumn;

	updateConfiguratorData({ ['reportSettings']: { columns: newColumns } }, false, 'columns');
};
